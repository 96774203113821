import { Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { flexAlignCenter } from "../../../theme/commonThemes";
import PlayDownloadButtons from "../Shared/PlayDownloadIButtons";
import Image from "next/image";
const useStyles = makeStyles((theme) => ({
  downloadSection: {
    backgroundColor: "#F4F5F6",
    paddingTop: "20px",
  },
  imageContainer: {
    width: "100%",
    height: "60vh",
    "& div": {
      position: "unset !important",
    },
  },
  image: {
    objectFit: "contain",
    width: "100% !important",
    position: "relative !important",
    height: "100%",
  },
}));

const DownloadSection = () => {
  const classes = useStyles();
  return (
    <div className={classes.downloadSection}>
      <Container maxWidth="lg">
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <Box sx={{ ...flexAlignCenter }}>
              <Box>
                <Typography variant="h1">Download the app</Typography>
                <Typography variant="h3" sx={{ padding: "16px 0px 20px 0px" }}>
                  Download now & avail all of our services through the app
                </Typography>
                <PlayDownloadButtons />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.imageContainer}>
              <Image
                src="/images/phone_mockup.svg"
                alt=""
                fill
                className={classes.image}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DownloadSection;
