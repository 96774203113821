import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useRouter } from "next/router";
import { flexBetweenCenter, flexCenter } from "../../../theme/commonThemes";
import GuestDrawer from "./GuestDrawer";
import SelectDateRange from "./SelectDateRange";
import LocationDrawer from "./LocationDrawer";
import {
  dateAdd,
  formatDateRange,
  formatYearDate,
} from "../../utilities/timeFunctionsModel";

const useStyles = makeStyles((theme) => ({}));
const LocationSearchMobile = ({
  placeName,
  fromDate,
  toDate,
  guest,
  child,
  infant,
  locationPrev,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const classes = useStyles();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [dateDrawerOpen, setDateDrawerOpen] = useState(false);
  const [guestDrawerOpen, setGuestDrawerOpen] = useState(false);
  const [locationDrawerOpen, setLocationDrawerOpen] = useState(false);
  const [options, setOptions] = useState({
    adult: guest ? parseInt(guest) : 0,
    children: child ? parseInt(child) : 0,
    infants: infant ? parseInt(infant) : 0,
  });

  const findMyLocation = async (guest, child, infant) => {
    if (selectedLocation) {
      let latitude = selectedLocation.lat;
      let longitude = selectedLocation.lng;
      if (selectedLocation && latitude && longitude && selectedPlace) {
        let location = `${latitude},${longitude}`;
        router.replace(
          `/locationSearch?location=${location}&${
            from
              ? `from=${formatYearDate(from)}`
              : fromDate && `from=${fromDate}`
          }&${
            to
              ? `to=${formatYearDate(to)}`
              : toDate
              ? `to=${toDate}`
              : from && `to=${dateAdd(from, 1)}`
          }&${`placeName=${selectedPlace}`}&${guest && `guest=${guest}`}&${
            child && `child=${child}`
          }&${infant && `infant=${infant}`}`,
          undefined,
          { shallow: true }
        );
        reset();
        setFrom();
        setTo();
        handleCloseOption();
      } else {
        return;
      }
    } else if (locationPrev) {
      router.replace(
        `/locationSearch?location=${locationPrev}&${
          from ? `from=${formatYearDate(from)}` : fromDate && `from=${fromDate}`
        }&${
          to ? `to=${formatYearDate(to)}` : toDate && `to=${toDate}`
        }&${`placeName=${placeName}`}&${guest && `guest=${guest}`}&${
          child && `child=${child}`
        }&${infant && `infant=${infant}`}`,
        undefined,
        { shallow: true }
      );
    } else {
      enqueueSnackbar("Please Select a valid Location", {
        variant: "error",
      });
    }
  };

  const schema = yup.object().shape({
    //guest: yup.string().required("Message is Required"),
  });
  const {
    handleSubmit,
    register,
    unregister,
    setValue,
    setError,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    await findMyLocation(options.adult, options.children, options.infants);
  };

  //date section
  const toggleDateDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDateDrawerOpen({ ...dateDrawerOpen, [anchor]: open });
  };

  //guest section
  const toggleGuestDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setGuestDrawerOpen({ ...guestDrawerOpen, [anchor]: open });
  };

  //location drawer
  const toggleLocationDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLocationDrawerOpen({ ...locationDrawerOpen, [anchor]: open });
  };

  const handleOption = (name, operation) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: operation === "i" ? options[name] + 1 : options[name] - 1,
      };
    });
  };

  const handleCloseOption = async (e) => {
    setOptions({
      adult: 0,
      children: 0,
      infants: 0,
    });
  };

  return (
    <React.Fragment>
      <SelectDateRange
        toggleDateDrawer={toggleDateDrawer}
        dateDrawerOpen={dateDrawerOpen}
        from={from}
        setFrom={setFrom}
        to={to}
        setTo={setTo}
      />
      <LocationDrawer
        toggleLocationDrawer={toggleLocationDrawer}
        locationDrawerOpen={locationDrawerOpen}
        setSelectedLocation={setSelectedLocation}
        setSelectedPlace={setSelectedPlace}
        setLocationDrawerOpen={setLocationDrawerOpen}
      />

      <GuestDrawer
        toggleGuestDrawer={toggleGuestDrawer}
        guestDrawerOpen={guestDrawerOpen}
        handleOption={handleOption}
        options={options}
        handleCloseOption={handleCloseOption}
        setGuestDrawerOpen={setGuestDrawerOpen}
      />

      <FormProvider
        {...{
          handleSubmit,
          register,
          unregister,
          setValue,
          errors,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box
                onClick={toggleLocationDrawer("bottom", true)}
                sx={{
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  borderRadius: "8px",
                  backgroundColor: "var(--color-white-main)",
                  height: "70px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.07)",
                  marginBottom: "16px",
                }}
              >
                <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <img src="/images/icons/location.svg" alt="" />
                </IconButton>
                <Box
                  className={classes.locationInput}
                  sx={{ width: "calc(100% - 10px)" }}
                >
                  <Typography variant="h6" sx={{ color: " #666870" }}>
                    Where do you want to stay?
                  </Typography>

                  <TextField
                    fullWidth
                    variant="standard"
                    placeholder="Enter a location"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "#1A1819",
                        padding: 0,
                        opacity: 1,
                      },
                    }}
                    value={
                      selectedPlace ? selectedPlace : placeName ? placeName : ""
                    }
                  />
                </Box>

                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <img src="/images/icons/search-normal.svg" alt="" />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                  backgroundColor: "var(--color-white-main)",
                  height: "70px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.07)",
                }}
                onClick={toggleDateDrawer("bottom", true)}
                className={classes.dateRangeRoot}
              >
                <Box sx={flexBetweenCenter}>
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <img src="/images/icons/calendar.svg" alt="" />
                  </IconButton>
                  <Box sx={{ paddingLeft: "10px" }}>
                    <Typography variant="h6" sx={{ color: "#666870" }}>
                      Check In - Check Out
                    </Typography>
                    <TextField
                      fullWidth
                      readOnly
                      autoComplete="off"
                      variant="standard"
                      placeholder="Select Date"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#1A1819",
                          padding: 0,
                          opacity: 1,
                        },
                      }}
                      value={
                        from && to
                          ? formatDateRange(from, to)
                          : fromDate && toDate
                          ? formatDateRange(fromDate, toDate)
                          : from && !to
                          ? formatDateRange(from, dateAdd(from, 1))
                          : ""
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                onClick={toggleGuestDrawer("bottom", true)}
                sx={{
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  borderRadius: "8px",
                  backgroundColor: "var(--color-white-main)",
                  height: "70px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.07)",
                  margin: "16px 0px",
                }}
              >
                <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <img src="/images/icons/profile.svg" alt="" />
                </IconButton>
                <Box
                  className={classes.locationInput}
                  sx={{ width: "calc(100% - 10px)" }}
                >
                  <Typography variant="h6" sx={{ color: " #666870" }}>
                    Guest
                  </Typography>

                  <TextField
                    readOnly
                    fullWidth
                    variant="standard"
                    placeholder="Guest"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "#1A1819",
                        padding: 0,
                        cursor: "pointer",
                      },
                    }}
                    value={
                      options.adult !== 0 ||
                      options.children !== 0 ||
                      options.infants !== 0
                        ? `${
                            parseInt(options.adult) +
                            parseInt(options.children) +
                            parseInt(options.infants)
                          } guests`
                        : ""
                    }
                    {...register("guest")}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ paddingBottom: "90px" }}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  fullWidth
                  variant="contained"
                  disableElevation
                  sx={{
                    height: "49px",
                    borderRadius: "8px",
                    fontWeight: 500,
                    fontSize: "14px",
                    margin: "8px 0px",
                  }}
                >
                  Search
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default LocationSearchMobile;
