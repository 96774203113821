import React, { useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { flexBetween } from "../../../theme/commonThemes";
import { makeStyles } from "@mui/styles";
import { enGB } from "date-fns/locale";
import { DateRangePickerCalendar, START_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import MobileHeader from "./Shared/MobileHeader";
import { useSnackbar } from "notistack";
import { dateDifference, formatDate } from "../../utilities/timeFunctionsModel";
const useStyles = makeStyles((theme) => ({
  dateRoot: {
    "& h4": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    "& .nice-dates-day_date": {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
    },
    "& .nice-dates-navigation_current": {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "24px",
      color: "#1A1819",
    },
    "& .nice-dates-week-header": {
      boxShadow: "none",
    },
    "& .nice-dates-week-header_day": {
      fontSize: 0,
    },
    "& .nice-dates-week-header_day:first-letter": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "#666870",
    },
    "& .nice-dates-day:before": {
      backgroundColor: "var(--primary-color)",
    },
    "& .nice-dates-day.-selected.-selected-middle:before": {
      backgroundColor: "#FFE4F1",
    },
    "& .nice-dates-day.-selected.-selected-middle": {
      "& .nice-dates-day_date": {
        color: "var(--text-primary) !important",
      },
    },
  },
}));

export default function SelectDateRange({
  dateDrawerOpen,
  toggleDateDrawer,
  from,
  setFrom,
  to,
  setTo,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [focus, setFocus] = useState(START_DATE);
  const handleFocusChange = (newFocus) => {
    setFocus(newFocus || START_DATE);
  };

  const handleError = () => {
    enqueueSnackbar("Please Select CheckOut Date", {
      variant: "error",
    });
  };

  return (
    <div>
      <SwipeableDrawer
        anchor="bottom"
        open={dateDrawerOpen["bottom"]}
        onOpen={toggleDateDrawer("bottom", true)}
        onClose={toggleDateDrawer("bottom", false)}
      >
        <Box
          sx={{
            padding: "16px 24px 24px 24px",
            height: "100vh",
          }}
          role="presentation"
          className={classes.dateRoot}
        >
          <Box onClick={toggleDateDrawer("bottom", false)}>
            <MobileHeader title="Select Date" />
          </Box>

          <Box
            sx={{
              ...flexBetween,
              py: 3,
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                sx={{ color: "var(--text-secondary)", marginBottom: "4px" }}
              >
                Check In
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                {from ? formatDate(from) : "Select Date"}
              </Typography>
            </Box>

            <Box
              sx={{
                border: "1px solid #F5F5F5",
                borderRadius: "8px",
                height: "46px",
                width: "86px",
                textAlign: "center",
              }}
            >
              <Typography variant="h6" sx={{ color: "var(--primary-color)" }}>
                {from && to ? dateDifference(from, to) : 0}
              </Typography>
              <Typography variant="h6" sx={{ color: "var(--primary-color)" }}>
                Days
              </Typography>
            </Box>

            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                sx={{ color: "var(--text-secondary)", marginBottom: "4px" }}
              >
                Check Out
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                {to ? formatDate(to) : "Select Date"}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ pt: 3, borderTop: "1px solid var(--border-color)" }}>
            <DateRangePickerCalendar
              startDate={from}
              endDate={to}
              minimumDate={new Date()}
              focus={focus}
              onStartDateChange={setFrom}
              onEndDateChange={setTo}
              onFocusChange={handleFocusChange}
              locale={enGB}
            />
          </Box>

          <Box
            sx={{
              p: 3,
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
            }}
          >
            <Button
              fullWidth
              sx={{
                fontWeight: 600,
                fontSize: " 14px",
                height: "56px",
                borderRadius: "4px",
                color: "var(--background-white)",
                zIndex: 999999,
              }}
              variant="contained"
              disableElevation
              onClick={
                from && !to ? handleError : toggleDateDrawer("bottom", false)
              }
            >
              Done
            </Button>
          </Box>
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
