import { Box, Grid, Paper } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import React from "react";
import LocationSearchMobile from "../LocationSearchMobile";
import MobileFooter from "../Shared/MobileFooter";
import { useRouter } from "next/router";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "url(/mobileImages/splash_screen.svg)",
    display: "block",
    height: "60vh",
    width: "100%",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function MobileHomePage() {
  const classes = useStyles();
  const router = useRouter();
  const { from, to, location, guest, child, infant, placeName } = router.query;
  const [openDate, setOpenDate] = React.useState(false);

  return (
    <>
      {router.isReady && (
        <Box>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box className={classes.root}></Box>
            </Grid>

            <Paper
              sx={{
                width: "100%",
                position: "absolute",
                bottom: 0,
                backgroundColor: "#F9F9FB",
                boxShadow: "0px -4px 64px rgba(169, 213, 234, 0.61)",
                borderRadius: "16px 16px 0px 0px",
              }}
            >
              <Box sx={{ width: "100%", p: "24px 16px" }}>
                <LocationSearchMobile
                  setOpenDate={setOpenDate}
                  openDate={openDate}
                  locationPrev={location}
                  fromDate={from && from}
                  toDate={to && to}
                  placeName={placeName && placeName}
                  guest={guest && guest}
                  child={child && child}
                  infant={infant && infant}
                />
              </Box>
            </Paper>
          </Grid>

          <MobileFooter />
        </Box>
      )}
    </>
  );
}
