import { makeStyles } from "@mui/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  downloadButtons: {
    display: "flex",
    gap: "1rem",
    marginTop: "1rem",
  },
}));
const PlayDownloadButtons = () => {
  const classes = useStyles();
  return (
    <div className={classes.downloadButtons}>
      <a
        href="https://apps.apple.com/app/id1562887010"
        target="_blank"
        title="Travela on App Store"
        rel="noopener noreferrer"
      >
        <img src="/images/button-app.svg" alt="" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.travela.xyz"
        target="_blank"
        title="Travela on Google Play"
        rel="noopener noreferrer"
      >
        <img src="images/button-play.svg" alt="" />
      </a>
    </div>
  );
};

export default PlayDownloadButtons;
