import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";

export default function WhyBestCard({ name, icon, details }) {
  return (
    <Box>
      <Card sx={{ height: "100%", backgroundColor: "transparent" }}>
        <CardHeader avatar={<img src={icon} width="100%" />} />
        <CardContent>
          <Typography gutterBottom variant="caption" component="div">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {details}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
