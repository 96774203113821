import { Box, Typography } from "@mui/material";
import React from "react";
import { flexAlignCenter } from "../../../../theme/commonThemes";

export default function MobileHeader({ title }) {
  return (
    <Box sx={flexAlignCenter}>
      <img src="/mobileImages/back_arrow.svg" alt="" />
      <Box sx={{ margin: "auto" }}>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
}
