import React, { useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import CustomCounter from "../../components/CustomCounter";
import { Grid, Typography } from "@mui/material";
import { flexBetween, flexBetweenCenter } from "../../../theme/commonThemes";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  guestRoot: {
    borderRadius: "24px 24px 0px 0px",
  },
}));

export default function GuestDrawer({
  toggleGuestDrawer,
  guestDrawerOpen,
  handleOption,
  options,
  handleCloseOption,
  setGuestDrawerOpen,
}) {
  const classes = useStyles();
  const handleDrawerClose = () => {
    handleCloseOption();
    setGuestDrawerOpen(false);
  };
  return (
    <div>
      <SwipeableDrawer
        classes={{ paper: classes.guestRoot }}
        anchor="bottom"
        open={guestDrawerOpen["bottom"]}
        onOpen={toggleGuestDrawer("bottom", true)}
        onClose={toggleGuestDrawer("bottom", false)}
      >
        <Box
          sx={{
            padding: "24px",
            minHeight: "403px",
          }}
          role="presentation"
        >
          <Box sx={flexBetween}>
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              Select Guest size
            </Typography>
            <Typography
              variant="h5"
              onClick={toggleGuestDrawer("bottom", false)}
            >
              Cancel
            </Typography>
          </Box>
          <Box sx={{ borderBottom: "1px solid #F5F5F5" }}>
            <CustomCounter
              name="adult"
              label="Adult"
              handleOption={handleOption}
              optionsName={options.adult}
              details="Ages 13 or above"
            />
          </Box>
          <Box sx={{ borderBottom: "1px solid #F5F5F5" }}>
            <CustomCounter
              name="children"
              label="Children"
              handleOption={handleOption}
              optionsName={options.children}
              details="Ages 2-12"
            />
          </Box>

          <Box>
            <CustomCounter
              name="infants"
              label="Infants"
              handleOption={handleOption}
              optionsName={options.infants}
              details="Under 2"
            />
          </Box>
          <Box sx={flexBetweenCenter}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    width: "100%",
                    height: "56px",
                    padding: "16px 8px",
                    border: "1px solid var(--border-color)",
                    color: "#666870",
                  }}
                  disableElevation
                  variant="outlined"
                  onClick={handleDrawerClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{
                    fontWeight: 600,
                    fontSize: " 14px",
                    width: "100%",
                    height: "56px",
                    padding: "16px 8px",

                    color: "var(--background-white)",
                  }}
                  variant="contained"
                  disableElevation
                  onClick={toggleGuestDrawer("bottom", false)}
                >
                  Done
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
