import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { flexCenter } from "../../../theme/commonThemes";
import { makeStyles } from "@mui/styles";
import MaplocationRepo from "../../repositories/MaplocationRepo";
import MobileHeader from "./Shared/MobileHeader";
import { useDispatch } from "react-redux";
import mapLocationModule from "../../modules/mapLocationModule";
const useStyles = makeStyles((theme) => ({}));
export default function LocationDrawer({
  toggleLocationDrawer,
  locationDrawerOpen,
  setSelectedLocation,
  setSelectedPlace,
  setLocationDrawerOpen,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isLoading, setIsloading] = useState(false);
  const [searchCity, setSearchCity] = useState("");
  let searchCityPrev = "";
  const [searchedPlaces, setSearchedPlaces] = useState(null);
  const [popularLocations, setPopularLocations] = useState(null);
  const FetchSearchResults = async () => {
    const res = await dispatch(mapLocationModule.getAll({ q: searchCity }));
    if (res.data) {
      setSearchedPlaces(res.data);
      setIsloading(false);
    }
  };
  const FetchPopularLocations = async () => {
    setIsloading(true);
    const response = await MaplocationRepo.getPopularLocation();
    if (response.status === 200 || response.success === true) {
      if (response.data) {
        
        setPopularLocations(response.data);
        setIsloading(false);
      }
    }
  };

  useEffect(() => {
    if (searchCity === "") {
      FetchPopularLocations();
      setSearchedPlaces(null);
    }
    if (searchCity.length > 0 && searchCity.length < 2) {
      if (searchCity !== searchCityPrev) {
        setIsloading(true);
        searchCityPrev = searchCity;
        FetchSearchResults();
      } else {
        return;
      }
    }
    if (searchCity.length > 1) {
      let interval = setInterval(async () => {
        if (searchCity !== searchCityPrev) {
          setIsloading(true);
          searchCityPrev = searchCity;
          FetchSearchResults();
        } else {
          return;
        }
      }, 3000);
      return () => {
        clearInterval(interval);
      };
    } else {
      //handleClose();
      return;
    }
  }, [searchCity]);

  const handleOnChange = (e) => {
    e.preventDefault();
    setSearchCity(e.target.value);
  };

  const handlePopularLocationClick = async (name, latitude, longitude) => {
    setSelectedPlace(name);
    setLocationDrawerOpen(false);
    if (longitude && latitude) {
      let location = { lat: latitude, lng: longitude };
      setSelectedLocation(location);
      document.getElementById("autocompleteInputField").value = "";
      setSearchCity("");
      setSearchedPlaces(null);
    }
  };
  const handleClose = () => {
    setLocationDrawerOpen(false);
    document.getElementById("autocompleteInputField").value = "";
    setSearchCity("");
    setSearchedPlaces(null);
  };
  return (
    <div>
      <SwipeableDrawer
        anchor="bottom"
        open={locationDrawerOpen["bottom"]}
        onOpen={toggleLocationDrawer("bottom", true)}
        onClose={toggleLocationDrawer("bottom", false)}
      >
        <Box
          sx={{
            padding: "16px 24px 24px 24px",
            height: "100vh",
          }}
          role="presentation"
        >
          <>
            <Box sx={{ backgroundColor: "var(--background-white)" }}>
              <Box onClick={handleClose}>
                <MobileHeader title="Search Location" />
              </Box>
              <Box
                sx={{
                  padding: "15px 16px",
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  backgroundColor: "#F6F8FA",
                  height: "48px",
                  borderRadius: "4px",
                  margin: "24px 0px 0px 0px",
                }}
              >
                <Box
                  className={classes.locationInput}
                  sx={{ width: "calc(100% - 10px)" }}
                >
                  <TextField
                    //   ref={searchInput}
                    fullWidth
                    variant="standard"
                    placeholder="Search"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        lineHeight: "18px",
                        fontWeight: 400,
                        fontSize: "15px",
                        color: "#666870",
                        padding: 0,
                        opacity: 1,
                      },
                    }}
                    onChange={(e) => handleOnChange(e)}
                    id="autocompleteInputField"
                  />
                </Box>
                {/* 
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <img src="/mobileImages/gps.svg" alt="" />
              </IconButton> */}
              </Box>
            </Box>

            <Box>
              <List>
                {!isLoading &&
                  searchedPlaces &&
                  searchedPlaces?.length > 0 &&
                  searchedPlaces?.map((predictedPlace, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ListItem
                          onClick={() => {
                            toggleLocationDrawer("bottom", false);
                            handlePopularLocationClick(
                              predictedPlace?.name,
                              predictedPlace?.lat,
                              predictedPlace?.lng
                            );
                          }}
                          disablePadding
                          sx={{ padding: "16px 0px" }}
                        >
                          <img src="/mobileImages/location.svg" alt="" />
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle1"
                                sx={{ ml: "12px", textTransform: "uppercase" }}
                              >
                                {predictedPlace?.name}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
              </List>
              {isLoading && (
                <Box
                  sx={{
                    ...flexCenter,
                    p: 8,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              {!isLoading && searchedPlaces && searchedPlaces?.length < 1 && (
                <Box
                  sx={{
                    ...flexCenter,
                    p: 8,
                  }}
                >
                  <Typography variant="subtitle1">No Data Found</Typography>
                </Box>
              )}
              <List sx={{ p: 0 }}>
                {!isLoading &&
                  !searchedPlaces &&
                  popularLocations &&
                  popularLocations?.length > 0 &&
                  popularLocations?.map((popularPlace, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ListItem
                          onClick={() => {
                            toggleLocationDrawer("bottom", false);
                            handlePopularLocationClick(
                              popularPlace?.name,
                              popularPlace?.lat,
                              popularPlace?.lng
                            );
                          }}
                          disablePadding
                          sx={{ padding: "16px 0px" }}
                        >
                          <img src="/mobileImages/location.svg" alt="" />
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle1"
                                sx={{ ml: "12px", textTransform: "uppercase" }}
                              >
                                {popularPlace?.name}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
              </List>
            </Box>
          </>
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
