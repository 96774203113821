import { Box, Hidden } from "@mui/material";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import MobileHomePage from "../src/MobileView/components/MobileHomePage";
import DownloadSection from "../src/components/HomePage/DownloadSection";
import HeroSection from "../src/components/HomePage/HeroSection";
import LocationSearchDesk from "../src/components/HomePage/LocationSearchDesk";
import WhyBestSection from "../src/components/HomePage/WhyBestSection";
import Footer from "../src/components/Shared/Footer";
import Header from "../src/components/Shared/Header";
import { profileData } from "../src/modules/auth";

const Home = () => {
  const router = useRouter();
  const { from, to, location, guest, child, infant, placeName } = router.query;
  const dispatch = useDispatch();
  const getProfileData = async () => {
    const res = await dispatch(profileData());
  };
  let count = 0;
  useEffect(() => {
    if (count === 0) {
      getProfileData();
    }
    count++;
  }, []);

  return (
    <>
      <Hidden only={["xs", "sm"]}>
        {router.isReady && (
          <Box>
            <Box>
              <Header />
            </Box>
            <Box>
              <HeroSection />
              <LocationSearchDesk
                locationPrev={location}
                fromDate={from && from}
                toDate={to && to}
                placeName={placeName && placeName}
                guest={guest && guest}
                child={child && child}
                infant={infant && infant}
              />
            </Box>
            <DownloadSection />
            <WhyBestSection />
            <Footer />
          </Box>
        )}
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <MobileHomePage />
      </Hidden>
    </>
  );
};

export default Home;
