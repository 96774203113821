import { Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import WhyBestCard from "../WhyBestCard";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "120px 0px 96px 0px",
    "& .MuiCardHeader-root": {
      padding: "44px 0px 28px 0px",
    },
    "& .MuiCardContent-root": {
      padding: 0,
      "& .MuiTypography-root": {
        width: "252px",
      },
    },
  },
}));
export default function WhyBestSection() {
  const classes = useStyles();
  const BenefitsList = [
    {
      icon: "/images/icons/benefit_1.svg",
      name: "Property Insurance",
      details:
        "We offer our customer property protection of liability coverage and insurance for their better life.",
    },
    {
      icon: "/images/icons/benefit_2.svg",
      name: "Best Price",
      details:
        "Not sure what  you should be charging for your property? No need to worry, let us do the numbers for you.",
    },
    {
      icon: "/images/icons/benefit_3.svg",
      name: "Lowest Commission",
      details:
        "You no longer have to negotiate commissions and haggle with other agents it only cost 2%!",
    },
    {
      icon: "/images/icons/benefit_4.svg",
      name: "Overall Control",
      details:
        "Get a virtual tour, and schedule visits before you rent or buy any properties. You get overall control.",
    },
  ];
  return (
    <Box sx={{ backgroundColor: "var(--background-white)" }}>
      <Container maxWidth="lg">
        <Box className={classes.root}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              justifyContent="center"
            >
              <Typography variant="h2">Why we are best?</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 2, md: 4 }}>
            {BenefitsList.map((benefit, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <WhyBestCard
                  name={benefit.name}
                  icon={benefit.icon}
                  details={benefit.details}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
