import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "url(/deskImages/home_thumb.svg)",
    display: "block",
    height: "40vh",
    width: "100%",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  boxRoot: {
    backgroundImage: "url(/deskImages/home_thumb.svg)",
    display: "block",
    minHeight: 432,
    width: "100%",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));
export default function HeroSection({ forSignInPage }) {
  const classes = useStyles();
  return (
    <>
      <Box className={forSignInPage ? classes.root : classes.boxRoot}></Box>
    </>
  );
}
